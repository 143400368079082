import 'v-calendar/dist/style.css';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js";

import { createApp } from 'vue'

import App from './App.vue'
import VCalendar from 'v-calendar';
import router from './router'
import { store } from './lib/store';

import "./assets/base.css";

const app = createApp(App);
app.use(VCalendar, {});
app.use(store);
app.use(router)
app.provide("$store", store);
app.mount("#app");