import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/rtic'
  },
  {
    path: '/rtic/',
    name: 'sin hash',
    component: () => import(/* webpackChunkName: "bonos-regalo" */ '../views/integracion_sin_web.vue')
  },
  {
    path: '/rtic/:hash',
    name: 'reserva',
    component: () => import(/* webpackChunkName: "bonos-regalo" */ '../views/reserva_menu.vue')
  },
  {
    path: '/rtic/ok/:hash/:uuid',
    name: 'ok',
    component: () => import(/* webpackChunkName: "ok" */ '../views/resultado_ok.vue')
  },
  {
    path: '/rtic/ko/:hash/:uuid?',
    name: 'ko',
    component: () => import(/* webpackChunkName: "ko" */ '../views/resultado_ko.vue')
  },
  {
    path: '/rtic/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '../views/reserva_error.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
